import Header from './components/Header';
import AnimatedRoutes from './components/AnimatedRoutes';

function App() {
  const personalDetails = {
    name: 'Ihsan Katmer',
    location: 'Istanbul, Türkiye',
    email: 'ihsankatmer@gmail.com',
    availability: '',
    brand:
        '',
  };

  return (
      <>
        <Header/>
        <AnimatedRoutes personalDetails={personalDetails}/>
      </>
  );
}

export default App;
