import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import Modal from "react-modal";

const Project = ({ project }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const {
    company,
    technologies,
    bgcolor,
    category,
    title,
    links,
    projectLogo,
    color,
    id,
    github,
    deployed,
    description,
  } = project;

  Modal.setAppElement("#root");

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const isValidUrl = (url) => {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const LinkList = () =>
    links.map((item, index) => (
      <div key={index} style={{ marginBottom: 10 }}>
        <p className="tech text-center">
          <em
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              color: "#7f7f7f",
            }}
          >
            {item.title}:
          </em>
        </p>
        <p className="tech text-center">
          {isValidUrl(item.link) ? (
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <em>{item.link}</em>
            </a>
          ) : (
            <em>{item.link}</em>
          )}
        </p>
        <p className="tech text-center">
          <em
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              color: "#7f7f7f",
            }}
          >
            {item.description}
          </em>
        </p>
      </div>
    ));

  return (
    <div ref={ref} className="col-sm-12 col-lg-6">
      <div
        style={{ backgroundColor: bgcolor }}
        className="projectCard d-flex flex-column align-items-start justify-content-center p-5"
        onClick={handleOpenModal}
      >
        <h3 className="companyTitle">{company?.companyName}</h3>
        <h3 className="projectTitle text-center">{title}</h3>
        <h2 className="category">{category}</h2>
        <p className="tech text-center">
          <em>{technologies}</em>
        </p>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            backgroundColor: "#101010",
            color: "#9f9f9f",
            padding: "4vw",
            display: "flex",
            flexDirection: "column",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 99,
            borderRadius: 15,
            borderColor: "#101010",
            maxWidth: "90vw",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 30,
          }}
        >
          <img
            src={projectLogo}
            onClick={handleCloseModal}
            style={{
              cursor: "pointer",
              width: "50px",
              height: "50px",
              marginRight: "10px",
              border: "1px solid black",
              borderRadius: "10px",
              backgroundColor: "white",
            }}
          />
          <h3
            className="modalTitle"
            style={{
              fontSize: "1.5rem",
            }}
          >
            {title}
          </h3>
          <div style={{ flex: 1 }} />
          <div
            onClick={handleCloseModal}
            style={{
              marginLeft: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                width: "24px",
                height: "24px",
                color: "black",
              }}
            />
          </div>
        </div>
        <p className="projectModalTitle">Project Description</p>
        <p className="projectDescription">{description}</p>
        <p className="projectModalTitle">Links</p>
        <LinkList />
        {/*  <button className="btn" onClick={() => (window.location.href = github)}>
          GitHub Repo
        </button>
        <button className="btn" onClick={() => (window.location.href = deployed)}>
          Live Link
        </button>*/}
      </Modal>
    </div>
  );
};

export default Project;
