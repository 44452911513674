import projectData from './projectsData.json';
import Project from '../../components/Project';
import PageHeader from '../../components/PageHeader';

const Portfolio = () => {
  const ProjectList = () =>
      projectData.map((project, i) => (
          <Project
              key={i}
              project={project}
          />
      ));

  return (
      <section className="portfolio">
        <PageHeader title="Portfolio" description="Hello, <br /><br />Thank you for visiting. My portfolio highlights a diverse array of projects, underscoring the evolution and enrichment of my professional skills and experiences. I'm enthusiastic about the prospect of collaboration. <br /><br />Best regards,<br /><br />Ihsan"/>
        <div className="row">
          <ProjectList/>
        </div>
      </section>
  );
};

export default Portfolio;
