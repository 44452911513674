import aboutMeImg from '../images/aboutme.jpeg';
import {motion} from 'framer-motion';
import SocialIcons from '../components/SocialIcons';
import {useInView} from 'react-intersection-observer';

const AboutMe = ({name, email, location, availability, brand}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
              className="personalImage col-12 col-lg-4"
              ref={ref}
              initial={{x: '-10vw', opacity: 0}}
              animate={inView ? {x: 0, opacity: 1} : {x: '-10vw', opacity: 0}}
              transition={{duration: 0.4, ease: 'easeInOut'}}
          >
            <img src={aboutMeImg} alt={name}/>
          </motion.div>
          <motion.div
              className="personalInfo col-12 col-lg-8"
              ref={ref}
              initial={{x: '10vw', opacity: 0}}
              animate={inView ? {x: 0, opacity: 1} : {x: '10vw', opacity: 0}}
              transition={{duration: 0.4, ease: 'easeInOut'}}
          >
            <div className="contentContainer">
              <h4>Hello, I am Ihsan!</h4>
            {/*  <h5>I am a mobile engineer who focuses on developing mobile apps
                using React Native. I enjoy building with TypeScript
                and JavaScript. 👨‍💻</h5>*/}
              <div className="contentDescription">
                <span>
                  I am often regarded as a helpful individual with a friendly, energetic disposition. I derive great satisfaction from engaging in the exchange of experiences and sharing knowledge with my peers.
                   <br/>  <br/>
My commitment to self-improvement drives me to continuously seek opportunities for personal growth and learning.
                   <br/> <br/>
I approach collaboration with great enthusiasm, particularly when working alongside talented individuals, and I remain steadfast in my commitment to consistently expanding my knowledge base.
                  <br/>  <br/> </span>
              {/*  <span> Beyond my passion for technology, I possess a deep interest in cultural enrichment, which I pursue through visits to museums and participation in various cultural activities to further my understanding.
                           <br/>        <br/>
I have recently introduced a mobile application called "MuseumsAround," designed to facilitate users in discovering local museums within their city. Should you wish to explore this project in greater detail, I invite you to access more information at the following link:   <br/>  <br/>
                </span>
                <a
                    href={'https://museumsaround.com'}>https://museumsaround.com</a>*/}
              </div>
              {/* <div className="infoContainer">
                <div className="row">
                  <div className="col-12 col-md-6 info">
                    <span>Personal Email:</span>
                    <p>
                      <a href={`mailto:${email}`}>{email}</a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 info">
                    <span>Availability:</span>
                    <p>{availability}</p>
                  </div>
                  <div className="col-12 col-md-6 info">
                    <span>Visa Status: </span>
                    <p>Require EU work visa</p>
                  </div>
                </div>
              </div>*/}
              <div className="buttonContainer">
                <div style={{marginTop: 50, height: 30, width: 20}}/>
                {' '}
                <SocialIcons/>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
  );
};

export default AboutMe;
