const SocialIcons = () => {
  const styles = {
    icon: {
      textDecoration: 'none',
      fontSize: '22px',
      padding: '10px',
      transition: '0.6s ease-in',
    },
  };

  return (
      <div className="socialIcons" style={styles.socialIcons}>
        <a className="icon" style={styles.icon}
           href="https://github.com/ihsanktmr">
          <i className="fa-brands fa-github" aria-hidden="true"
             title="Github"></i>
        </a>
        <a className="icon" style={styles.icon}
           href="https://www.linkedin.com/in/ihsanktmr/">
          <i className="fa-brands fa-linkedin" aria-hidden="true"
             title="LinkedIn"></i>
        </a>
        {/*  <a className="icon" style={styles.icon}
           href="https://www.instagram.com/michael.yeates/">
          <i className="fa-brands fa-instagram" aria-hidden="true"
             title="Instagram"></i>
        </a>*/}
      </div>
  );
};

export default SocialIcons;
