const PageHeader = ({title, description}) => (
    <>
      <div className="pageDescriptionContainer">
        <p className="pageDescription" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <h3 className="pageTitle">{title}</h3>
    </>
);

export default PageHeader;
