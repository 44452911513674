import NavLinks from './NavLinks';

const Header = () => {
  return (
      <header className="header">
        <div/>
        <NavLinks/>
      </header>
  );
};

export default Header;
